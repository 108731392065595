import React, { useEffect, useRef } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { delay, filter, find, join, map, range, size } from 'lodash';
import { imageUrl, processedImageUrl } from 'helpers/url.helper';
import {
  FILTER_GRID_SIZE,
  INIT_FILTER_ID,
  SHOOTING_TYPES,
  TIME_DELAY_PROCESS_VIDEO,
  TIME_TO_DELAY_REDIRECT_PRINT,
} from 'constants/photo.const';
import { I18nNamespace } from 'constants/i18n.const';
import { LayoutPhotoModel } from 'models/photo/layout.model';
import { FilterPhotoModel } from 'models/photo/filter.model';
import { setSelfeAppStateAction } from 'store/features/app/selfeAppSlice';
import { useAppDispatch, useAppSelector } from 'store/store-hooks';
import { isEqualVal } from 'helpers/string.helper';
import { diffFromDate } from 'helpers/time.helper';
import { useSoundContext } from 'context/SoundContext';
import { useBoothAppContext } from 'context/BoothAppContext';
import { useNewPhotoLifeCycleStep } from 'hooks/useNewPhotoLifeCycleStep';
import { usePageTimer } from 'hooks/usePageTimer';
import Typography from 'components/typography/Typography';
import { TYPOGRAPHY_VARIANTS } from 'components/typography/typography-utils';
import TimerText from 'components/timer-text/TimerText';
import PhotoSheet from 'components/photo-sheet/PhotoSheet';
import PageActions from 'components/page-actions/PageActions';
import {
  FILTER_MATRIX,
  PhotoFilterTypes,
} from 'components/photo-sheet/photo-sheet-utils';
import {
  useProcessPhotoTakenApiActionMutation,
  useProcessVideoTakenApiActionMutation,
} from 'store/api/photo-taken.slice-api';
import './add-filter.css';
import { SelfeAppStateModel } from '../../../models/app.model';
import { TrackingStateModel } from '../../../models/photo/tracking.model';

function AddFilter() {
  const { t } = useTranslation([I18nNamespace.COMMON, I18nNamespace.PAGE]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { audio } = useSoundContext();
  const { getPrevPath, getNextPath } = useNewPhotoLifeCycleStep();
  const [processPhotoTaken] = useProcessPhotoTakenApiActionMutation();
  const [processVideoTaken] = useProcessVideoTakenApiActionMutation();
  const { listFilterPhotos, themeDetail, currentLayout } = useBoothAppContext();
  const refTarget = useRef<HTMLInputElement | null>(null);
  const {
    frameId,
    layoutId,
    themeId,
    themeDetailId,
    depositAmount,
    quantitySheet,
    appliedVoucherCode,
    appliedVoucherValue,
    shootingModeId,
    filterId,
    transactionId,
    pickedPhotos,
    listSelectedSticker,
  } = useAppSelector(
    (state: { selfeApp: SelfeAppStateModel }) => state.selfeApp,
  );
  const {
    LastStartTimePurchase,
    LastStartTimeTakePhoto,
    LastStartTimeEditPhoto,
  } = useAppSelector(
    (state: { tracking: TrackingStateModel }) => state.tracking,
  );
  const { second, resetTime } = usePageTimer({
    forwardFunc: () => handleNext(),
  });

  const currentFilter = find(listFilterPhotos?.data, (o) =>
    isEqualVal(o?.id, filterId),
  );

  const costOfPayment =
    find(currentLayout?.prices, (o) =>
      isEqualVal(o?.numberOfPicture, quantitySheet),
    )?.price ||
    currentLayout?.prices?.[0]?.price ||
    0;

  const handleSelectFilter = (id: FilterPhotoModel['id']) => () => {
    if (filterId !== id) {
      audio?.playClickToSelect?.();
      dispatch(setSelfeAppStateAction({ filterId: id }));
    }
  };
  const calculateSizeAfterRotation = (w: number, h: number, deg: number) => {
    const radians = (deg * Math.PI) / 180;

    // Calculate new width and height
    const cos = Math.abs(Math.cos(radians));
    const sin = Math.abs(Math.sin(radians));
    const newWidth = w * cos + h * sin;
    const newHeight = w * sin + h * cos;

    return { width: Math.round(newWidth), height: Math.round(newHeight) };
  };

  const calculateCoordinatesAfterRotation = (
    originalX: number,
    originalY: number,
    originW: number,
    originH: number,
    newWidth: number,
    newHeight: number,
  ) => {
    // Convert angle from degrees to radians
    const centerPointX = originalX + originW / 2;
    const centerPointY = originalY + originH / 2;

    const newX = centerPointX - newWidth / 2;
    const newY = centerPointY - newHeight / 2;

    return { x: newX, y: newY };
  };

  const handleBack = () => {
    audio?.playBackClick?.();
    navigate(getPrevPath() || '');
  };
  const handleNext = async () => {
    resetTime();
    audio?.playContinueClick?.();
    const isHasVideo =
      !shootingModeId || isEqualVal(shootingModeId, SHOOTING_TYPES.COUNTDOWN);
    const layoutAmount =
      +(currentLayout as LayoutPhotoModel)?.prices?.[0]?.price || 0;
    const listStickerFormData = map(
      filter(listSelectedSticker, (i) => i.width > 0 && i.height > 0),
      (item: {
        stickerId: any;
        rotate: any;
        width: any;
        height: any;
        translate: any[];
      }) => {
        const newSize = calculateSizeAfterRotation(
          item.width,
          item.height,
          item.rotate,
        );
        const newCoordinates = calculateCoordinatesAfterRotation(
          item.translate[0],
          item.translate[1],
          item.width,
          item.height,
          newSize.width,
          newSize.height,
        );
        return {
          stickerId: item.stickerId,
          rotate: Math.round(item.rotate),
          width: Math.round(
            (newSize.width / (refTarget?.current?.clientWidth || 0)) *
              (currentLayout?.width || 0),
          ),
          height: Math.round(
            (newSize.height / (refTarget?.current?.clientHeight || 0)) *
              (currentLayout?.height || 0),
          ),
          axisX: Math.round(
            (Math.round(newCoordinates.x) /
              (refTarget?.current?.clientWidth || 0)) *
              (currentLayout?.width || 0),
          ),
          axisY: Math.round(
            (Math.round(newCoordinates.y) /
              (refTarget?.current?.clientHeight || 0)) *
              (currentLayout?.height || 0),
          ),
        };
      },
    );
    dispatch(setSelfeAppStateAction({ appLoading: true }));
    // process ảnh
    await processPhotoTaken({
      frameId,
      layoutId,
      themeId,
      themeDetailId,
      transactionId,
      filterId,
      listImages: filter(pickedPhotos, (i) => i !== null).map((item) => ({
        fileName: item?.fileName || '',
        rotate: item?.rotate || 0,
        flip: item?.flip === -1 ? 0 : null,
      })),
      isFile: true,
      isVideo: isHasVideo,
      voucherCode: appliedVoucherCode,
      purchaseDuration: diffFromDate(
        LastStartTimePurchase,
        LastStartTimeTakePhoto,
      ),
      captureDuration: diffFromDate(
        LastStartTimeTakePhoto,
        LastStartTimeEditPhoto,
      ),
      editDuration: diffFromDate(LastStartTimeEditPhoto, new Date()),
      captureMode: shootingModeId,
      printNumber: quantitySheet,
      layoutAmount,
      printAmount: costOfPayment - layoutAmount,
      discount: appliedVoucherValue,
      deposit: depositAmount - appliedVoucherValue,
      listSticker: listStickerFormData,
    });
    // process ảnh
    await delay(() => {
      processVideoTaken({
        frameId,
        layoutId,
        themeId,
        themeDetailId,
        transactionId,
        filterId,
        listImages: filter(pickedPhotos, (i) => i !== null).map((item) => ({
          fileName: item?.fileName || '',
          rotate: item?.rotate || 0,
          flip: item?.flip === -1 ? 0 : null,
        })),
        isFile: true,
        isVideo: isHasVideo,
        voucherCode: appliedVoucherCode,
        purchaseDuration: diffFromDate(
          LastStartTimePurchase,
          LastStartTimeTakePhoto,
        ),
        captureDuration: diffFromDate(
          LastStartTimeTakePhoto,
          LastStartTimeEditPhoto,
        ),
        editDuration: diffFromDate(LastStartTimeEditPhoto, new Date()),
        captureMode: shootingModeId,
        printNumber: quantitySheet,
        layoutAmount,
        printAmount: costOfPayment - layoutAmount,
        discount: appliedVoucherValue,
        deposit: depositAmount - appliedVoucherValue,
        listSticker: listStickerFormData,
      });
    }, TIME_DELAY_PROCESS_VIDEO);
    delay(() => {
      navigate(getNextPath() || '');
      dispatch(setSelfeAppStateAction({ appLoading: false }));
    }, TIME_TO_DELAY_REDIRECT_PRINT);
  };

  useEffect(() => {
    dispatch(
      setSelfeAppStateAction({
        filterId: listFilterPhotos?.data?.[0]?.id || INIT_FILTER_ID,
      }),
    );
  }, [dispatch, listFilterPhotos]);
  return (
    <>
      <div>
        <TimerText second={second} />
        <div className="page-title-margin filter-title">
          <Typography
            variant={TYPOGRAPHY_VARIANTS.H1}
            data-text={t(`${I18nNamespace.COMMON}:editColor`)}
            className="page-title"
          >
            {t(`${I18nNamespace.COMMON}:editColor`)}
          </Typography>
        </div>
        <div className="flex items-center justify-center filter-wrapper">
          <div className="filter-image-wrapper transition-img">
            <PhotoSheet
              className="add-filter-image-wrapper-example-img"
              forwardedRef={refTarget}
              data={{
                ...currentLayout,
                pictures: map(currentLayout?.pictures, (item, ind: number) => ({
                  ...item,
                  image: pickedPhotos?.[ind]
                    ? processedImageUrl(
                        transactionId,
                        pickedPhotos?.[ind]?.fileName,
                      )
                    : '',
                  rotate: pickedPhotos?.[ind]?.rotate,
                  flip: pickedPhotos?.[ind]?.flip,
                })),
                image: themeDetail?.image || currentLayout?.image,
              }}
              filterValues={currentFilter?.colorMatrix}
            />
          </div>
          <div className="filter-grid-wrapper">
            {map(range(FILTER_GRID_SIZE), (__, index) => (
              <div className="filter-line">
                {map(range(FILTER_GRID_SIZE), (_, ind) => {
                  const filterData = listFilterPhotos?.data?.[
                    index * FILTER_GRID_SIZE + ind
                  ] as FilterPhotoModel;
                  return (
                    <button
                      type="button"
                      className={cx('filter-item', {
                        active: !!isEqualVal(filterId, filterData?.id),
                      })}
                      onClick={handleSelectFilter(filterData?.id)}
                    >
                      <img
                        src={imageUrl(filterData?.image)}
                        alt="filter-img"
                        className="filter-thumbnail no-drag"
                      />
                      <svg className="thumbnail-filter-svg">
                        <filter
                          id={`filter-image-${filterData?.id}`}
                          colorInterpolationFilters="sRGB"
                        >
                          <feColorMatrix
                            type="matrix"
                            values={join(
                              size(filterData?.colorMatrix)
                                ? filterData?.colorMatrix
                                : FILTER_MATRIX?.[PhotoFilterTypes.NONE],
                            )}
                          />
                        </filter>
                        <image
                          filter={`url(#filter-image-${filterData?.id})`}
                          xlinkHref={imageUrl(filterData?.image)}
                          x="0"
                          y="0"
                          width="100%"
                          height="100%"
                        />
                      </svg>
                      <Typography
                        className="filter-name"
                        variant={TYPOGRAPHY_VARIANTS.SMALL}
                      >
                        {filterData?.name}
                      </Typography>
                    </button>
                  );
                })}
              </div>
            ))}
          </div>
        </div>
      </div>
      <PageActions
        NextButtonProps={{ children: t('page:print') }}
        handleBack={handleBack}
        handleContinue={handleNext}
      />
    </>
  );
}

export default AddFilter;
