import cx from 'classnames';
import CachedImage from 'components/cached-image/CachedImage';
import Typography from 'components/typography/Typography';
import { TYPOGRAPHY_VARIANTS } from 'components/typography/typography-utils';
import { I18nNamespace } from 'constants/i18n.const';
import { FRAME_TO_PAYMENT_DIRECTLY } from 'constants/photo.const';
import { emitSeqLog } from 'functions/seq-logging.func';
import { isEqualVal } from 'helpers/string.helper';
import { resourceUrl } from 'helpers/url.helper';
import { useNewPhotoLifeCycleStep } from 'hooks/useNewPhotoLifeCycleStep';
import { usePageTimer } from 'hooks/usePageTimer';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { usePrintPhotoApiActionMutation } from 'store/api/print.slice-api';
import { useAppSelector } from 'store/store-hooks';
import './print.css';

function Print() {
  const { t } = useTranslation([I18nNamespace.COMMON, I18nNamespace.PAGE]);
  const navigate = useNavigate();

  const { getStartNodePath } = useNewPhotoLifeCycleStep();
  const [printPhotoApiAction] = usePrintPhotoApiActionMutation();
  usePageTimer({
    forwardFunc: () => handleRedirectToStart(),
    isNotShowConfirm: true,
  });

  const { frameId, layoutId, transactionId, quantitySheet } = useAppSelector(
    (state) => state.selfeApp,
  );
  const { boothInfo } = useAppSelector((state) => state.booth);

  // Redirect back lại bắt đầu
  const handleRedirectToStart = () => {
    emitSeqLog?.({ messageTemplate: `[Print] End transaction!` });
    navigate(getStartNodePath() || '');
  };

  useEffect(() => {
    const printNumber = isEqualVal(FRAME_TO_PAYMENT_DIRECTLY, frameId)
      ? Math.max(quantitySheet / 2, 1)
      : quantitySheet;
    printPhotoApiAction({
      transactionId,
      numberOfImage: printNumber,
      layoutId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="print-title-container">
      <div
        className={cx(
          'print-title-wrapper text-center whitespace-pre-line print-title-wrapper',
          {
            'is-has-mascot': boothInfo?.PrintMascotImag,
          },
        )}
      >
        <Typography
          variant={TYPOGRAPHY_VARIANTS.H1}
          className="print-title"
          data-text={t(`${I18nNamespace.COMMON}:pleaseWaitForPrint`)}
        >
          {t(`${I18nNamespace.COMMON}:pleaseWaitForPrint`)}
        </Typography>
      </div>
      {!!boothInfo?.PrintMascotImag && (
        <div className="print-image-wrapper">
          <CachedImage
            imageUrl={resourceUrl(boothInfo?.StartMascotImage)}
            alt="macos"
          />
        </div>
      )}
    </div>
  );
}

export default Print;
