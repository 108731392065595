import React, { useCallback, useEffect, useState } from 'react';
import { find, map } from 'lodash';
import { useDebouncedCallback } from 'use-debounce';
import useConditionalTimeout from 'beautiful-react-hooks/useConditionalTimeout';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppContext } from 'context/AppContext';
import { useSoundContext } from 'context/SoundContext';
import { useBoothAppContext } from 'context/BoothAppContext';
import SquareButton from 'components/button/SquareButton';
import Typography from 'components/typography/Typography';
import { TYPOGRAPHY_VARIANTS } from 'components/typography/typography-utils';
import Input from 'components/input/Input';
import PageActions from 'components/page-actions/PageActions';
import TimerText from 'components/timer-text/TimerText';
import KeyboardDrawer from 'containers/selfe-booth/payment/KeyboardDrawer';
import { useAppDispatch, useAppSelector } from 'store/store-hooks';
import { setSelfeAppStateAction } from 'store/features/app/selfeAppSlice';
import { useFirstPushTransactionApiActionMutation } from 'store/api/transaction.slice.api';
import {
  useCloseBillAcceptorApiActionMutation,
  useOpenBillAcceptorApiActionMutation,
} from 'store/api/payment.slice-api';
import {
  CLOSE_AFTER_TIME_DURATION,
  PAYMENT_DEPOSIT_DEBOUNCE_TIME,
} from 'constants/photo.const';
import { BROADCAST_EVENTS } from 'constants/dom-element.const';
import { BE_DEFINES } from 'constants/backend-defines.const';
import { I18nNamespace } from 'constants/i18n.const';
import { isEqualVal } from 'helpers/string.helper';
import { formatMoneyVN } from 'helpers/math.helper';
import { useNewPhotoLifeCycleStep } from 'hooks/useNewPhotoLifeCycleStep';
import { useModal } from 'hooks/useModal';
import { usePageTimer } from 'hooks/usePageTimer';
import { SocketEventDataModel } from 'models/photo/socket.model';
import { emitSeqLog } from 'functions/seq-logging.func';
import './payment.css';

function Payment() {
  const { t } = useTranslation([I18nNamespace.COMMON, I18nNamespace.PAGE]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { audio } = useSoundContext();
  const { openConfirmModal, closeConfirmModal } = useModal();
  const { isOnline } = useAppContext();
  const { currentLayout } = useBoothAppContext();
  const { second, resetTime } = usePageTimer();
  const { getPrevPath, getNextPath } = useNewPhotoLifeCycleStep();
  const [openBillAcceptorApiAction] = useOpenBillAcceptorApiActionMutation();
  const [closeBillAcceptorApiAction] = useCloseBillAcceptorApiActionMutation();
  const [firstPushTransactionApiAction] =
    useFirstPushTransactionApiActionMutation();
  const {
    quantitySheet,
    transactionId,
    depositAmount,
    appliedVoucherCode,
    appliedVoucherValue,
  } = useAppSelector((state) => state.selfeApp);
  const [keyboardOpen, setKeyboardOpen] = useState<boolean>(false);

  const handleChangeAmountMorePic = (quantity: number) => () =>
    dispatch(setSelfeAppStateAction({ quantitySheet: quantity }));

  // Tại thời điểm click “Tiếp tục” Xác nhận số tiền nạp > tiền thanh toán
  const handleBeforeNextWarningAboutMoney = () => {
    if (depositAmount > costOfPayment) {
      audio?.playRedundantDeposit?.();
      openConfirmModal({
        content: `${t('page:changeMoneyWarning')}`,
        onOk: () => {
          audio?.playContinueClick?.();
          closeConfirmModal?.();
          navigate(getNextPath() || '');
        },
      });
    } else {
      audio?.playContinueClick?.();
      navigate(getNextPath() || '');
    }
    // Lưu transaction
    firstPushTransactionApiAction({
      id: transactionId,
      voucherCode: appliedVoucherCode,
      deposit: depositAmount - appliedVoucherValue,
    });
  };

  // click Back
  const handleBack = () => {
    audio?.playBackClick?.();
    navigate(getPrevPath() || '');
  };

  // voucher
  const handleShowKeyboard = useCallback(
    (isClose: boolean = false) =>
      () =>
        setKeyboardOpen(!isClose),
    [],
  );
  const handleApplyVoucher = useCallback(
    (voucher: string, voucherValue: number) => {
      const newDepositAmount = depositAmount + voucherValue;
      dispatch(
        setSelfeAppStateAction({
          isPaidButNotShooting: true,
          depositAmount: newDepositAmount,
          appliedVoucherCode: voucher,
          appliedVoucherValue: voucherValue,
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [depositAmount, transactionId, dispatch],
  );

  const handleDeposit: any = useDebouncedCallback(
    useCallback(
      async (e: CustomEvent<SocketEventDataModel>) => {
        if (isEqualVal(e?.detail?.typeHub, BE_DEFINES.HUB_TYPE.BILL_ACCEPTOR)) {
          emitSeqLog?.({
            messageTemplate: `[Payment] Deposit: ${+e?.detail?.data || 0}`,
          });
          const newDepositAmount = depositAmount + (+e?.detail?.data || 0);
          audio?.playDeposit?.();
          resetTime();
          dispatch(
            setSelfeAppStateAction({
              isPaidButNotShooting: true,
              depositAmount: newDepositAmount,
            }),
          );
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [dispatch, resetTime, transactionId, depositAmount],
    ),
    PAYMENT_DEPOSIT_DEBOUNCE_TIME,
    { trailing: false, leading: true },
  );

  const costOfPayment =
    find(currentLayout?.prices, (o) =>
      isEqualVal(o?.numberOfPicture, quantitySheet),
    )?.price ||
    currentLayout?.prices?.[0]?.price ||
    0;

  // open bill acceptor session
  useEffect(() => {
    if (transactionId && depositAmount < costOfPayment) {
      openBillAcceptorApiAction({ transactionId });
    }
  }, [
    dispatch,
    openBillAcceptorApiAction,
    transactionId,
    depositAmount,
    costOfPayment,
  ]);

  // close bill acceptor session
  useConditionalTimeout(
    () => closeBillAcceptorApiAction(),
    CLOSE_AFTER_TIME_DURATION,
    depositAmount >= costOfPayment,
  );

  // Add event broadcast bill acceptor
  useEffect(() => {
    document.addEventListener(BROADCAST_EVENTS.BILL_ACCEPTOR, handleDeposit);
    return () => {
      document.removeEventListener(
        BROADCAST_EVENTS.BILL_ACCEPTOR,
        handleDeposit,
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div>
        <TimerText second={second} />
        <div className="page-title-margin mb-[6rem]">
          <Typography
            variant={TYPOGRAPHY_VARIANTS.H1}
            data-text={t(`${I18nNamespace.COMMON}:payment`)}
            className="page-title"
          >
            {t(`${I18nNamespace.COMMON}:payment`)}
          </Typography>
        </div>
        <div className="payment-form">
          <div className="flex items-center payment-form-row">
            <div className="text-right payment-left-group">
              <Typography
                className="font-semibold"
                variant={TYPOGRAPHY_VARIANTS.H2}
                firstCapCase
              >
                {t('page:printAmount')}
              </Typography>
            </div>
            <div className="payment-right-group">
              <div className="button-group-more-pic">
                {map(currentLayout?.prices, (item) => (
                  <SquareButton
                    key={item?.numberOfPicture}
                    className="button-more-pic"
                    active={isEqualVal(item?.numberOfPicture, quantitySheet)}
                    onClick={handleChangeAmountMorePic(item?.numberOfPicture)}
                  >
                    {item?.numberOfPicture}
                  </SquareButton>
                ))}
              </div>
            </div>
          </div>
          <div className="flex items-center payment-form-row">
            <div className="text-right payment-left-group">
              <Typography
                className="font-semibold"
                variant={TYPOGRAPHY_VARIANTS.H2}
                firstCapCase
              >
                {t('page:voucherCode')}
              </Typography>
            </div>
            <div className="payment-right-group">
              <Input
                className="input-voucher"
                value={
                  appliedVoucherCode
                    ? `${appliedVoucherCode} +${formatMoneyVN(
                        appliedVoucherValue,
                      )} đ`
                    : ''
                }
                placeholder={`${t('page:voucherCodePlaceholder')}`}
                readOnly
                disabled={!isOnline || !!appliedVoucherCode}
                onClick={handleShowKeyboard(!isOnline)}
              />
            </div>
          </div>
          <div className="flex items-center payment-form-row">
            <div className="text-right payment-left-group">
              <Typography
                className="font-semibold"
                variant={TYPOGRAPHY_VARIANTS.H2}
                firstCapCase
              >
                {t('page:needPayment')}
              </Typography>
            </div>
            <div className="payment-right-group">
              <Input
                className="input-need-pay"
                value={`${formatMoneyVN(costOfPayment)} đ`}
                readOnly
              />
            </div>
          </div>
          <div className="flex items-center payment-form-row">
            <div className="text-right payment-left-group">
              <Typography
                className="font-semibold"
                variant={TYPOGRAPHY_VARIANTS.H2}
                firstCapCase
              >
                {t('page:deposited')}
              </Typography>
            </div>
            <div className="payment-right-group">
              <Input
                className="input-deposited"
                value={`${formatMoneyVN(depositAmount || 0)} đ`}
                readOnly
              />
            </div>
          </div>
          <div className="flex items-center payment-form-row change-money-warning">
            <div className="text-right payment-left-group" />
            <div className="payment-right-group">
              <Typography
                className="change-money-warning-text"
                variant={TYPOGRAPHY_VARIANTS.SMALL}
                firstCapCase
              >
                {depositAmount > costOfPayment
                  ? t('page:changeMoneyWarning')
                  : t('page:onlyAcceptSpecifyMoney')}
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <KeyboardDrawer
        open={keyboardOpen}
        onClose={handleShowKeyboard(true)}
        onOk={handleApplyVoucher}
      />
      <PageActions
        NextButtonProps={{
          children: t('common:continue'),
          disabled: depositAmount < costOfPayment || !isOnline,
        }}
        handleBack={handleBack}
        handleContinue={handleBeforeNextWarningAboutMoney}
      />
    </>
  );
}

export default Payment;
