import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useDebouncedCallback from 'beautiful-react-hooks/useDebouncedCallback';
import cx from 'classnames';
import { Carousel } from 'components/carousel/Carousel';
import PageActions from 'components/page-actions/PageActions';
import Typography from 'components/typography/Typography';
import {
  TYPOGRAPHY_COMPONENT,
  TYPOGRAPHY_VARIANTS,
} from 'components/typography/typography-utils';
import { I18nNamespace } from 'constants/i18n.const';
import {
  CAPTURE_POSITION_TEMPLATE_MAX_LENGTH,
  NUMBER_OF_SHOOTING_POSITION_PER_PAGE,
  TIME_DEBOUNCE_UPDATE_ROBOT_ARM,
} from 'constants/photo.const';
import { useBoothAppContext } from 'context/BoothAppContext';
import { useSoundContext } from 'context/SoundContext';
import { isEqualVal } from 'helpers/string.helper';
import { liveViewUrl } from 'helpers/url.helper';
import { useNewPhotoLifeCycleStep } from 'hooks/useNewPhotoLifeCycleStep';
import { compact, map, range, size, slice } from 'lodash';
import { CapturePositionModel } from 'models/photo/camera.model';
import { RobotArmPositionModel } from 'models/photo/robot-arm.model';
import {
  useLazyGetRobotArmPositionApiActionQuery,
  useLazyGetRobotArmStatusApiActionQuery,
  useUpdateRobotArmPoseApiActionMutation,
  useUpdateRobotArmPositionApiActionMutation,
} from 'store/api/robot-arm.slice-api';
import { setSelfeAppStateAction } from 'store/features/app/selfeAppSlice';
import { useAppDispatch, useAppSelector } from 'store/store-hooks';
import {
  checkIsIdleArm,
  checkSamePosition,
  convertCapturePositionToArmPosition,
} from './shooting-position-helper';
import './shooting-position.css';

function ShootingPosition() {
  const { t } = useTranslation([
    I18nNamespace.COMMON,
    I18nNamespace.PAGE,
    I18nNamespace.WARNING,
  ]);
  const { audio } = useSoundContext();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { getNextPath } = useNewPhotoLifeCycleStep();
  const [updateRobotArmPositionApiAction] =
    useUpdateRobotArmPositionApiActionMutation();
  const { listTimesMappingCapturePosition } = useAppSelector((s) => s.selfeApp);
  const { isRobotArm } = useAppSelector((state) => state.booth);
  const { currentLayout, listCapturePosition } = useBoothAppContext();

  const [liveViewHash] = useState(Date.now());
  const [shootingTimesIndex, setShootingTimesIndex] = useState(0);

  // Robot Arm
  const [getRobotArmStatusApiAction] = useLazyGetRobotArmStatusApiActionQuery();
  const [getRobotArmPositionApiAction] =
    useLazyGetRobotArmPositionApiActionQuery();
  const [updateRobotArmPoseApiAction] =
    useUpdateRobotArmPoseApiActionMutation();

  const handleMoveRobotArm = useDebouncedCallback(
    async (armPosition: RobotArmPositionModel | null) => {
      if (armPosition?.point) {
        const statusRes = await getRobotArmStatusApiAction();
        const armPositionRes = await getRobotArmPositionApiAction();
        if (
          checkIsIdleArm(statusRes?.data?.state) &&
          !checkSamePosition(armPositionRes?.data, armPosition)
        ) {
          await updateRobotArmPositionApiAction(armPosition);
        }
      }
    },
    [],
    TIME_DEBOUNCE_UPDATE_ROBOT_ARM,
    { trailing: false, leading: true },
  );

  const handleClickSelectShootingTimes = useCallback(
    (index: number) => () => {
      setShootingTimesIndex(index);
      if (listTimesMappingCapturePosition?.[index]) {
        handleMoveRobotArm(listTimesMappingCapturePosition?.[index]);
      }
    },
    [handleMoveRobotArm, listTimesMappingCapturePosition],
  );

  const handleClickSelectShootingPosition = useCallback(
    (capturePosition: CapturePositionModel) => {
      dispatch(
        setSelfeAppStateAction({
          listTimesMappingCapturePosition: map(
            listTimesMappingCapturePosition,
            (item, ind) => {
              if (isEqualVal(ind, shootingTimesIndex)) {
                return convertCapturePositionToArmPosition(capturePosition);
              }
              return item;
            },
          ),
        }),
      );
      if (convertCapturePositionToArmPosition(capturePosition)) {
        handleMoveRobotArm(
          convertCapturePositionToArmPosition(capturePosition),
        );
      }
    },
    [
      dispatch,
      handleMoveRobotArm,
      listTimesMappingCapturePosition,
      shootingTimesIndex,
    ],
  );

  const handleNext = async () => {
    audio?.playContinueClick?.();
    navigate(getNextPath() || '');
  };

  useEffect(() => {
    if (listCapturePosition?.data?.[0]) {
      dispatch(
        setSelfeAppStateAction({
          listTimesMappingCapturePosition: map(new Array(8), (_i, ind) =>
            !ind
              ? convertCapturePositionToArmPosition(
                  listCapturePosition?.data?.[0] as CapturePositionModel,
                )
              : null,
          ),
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (isRobotArm && listCapturePosition?.data?.[0]) {
      const asyncUpdatePose = async () => {
        const statusRes = await getRobotArmStatusApiAction();
        if (checkIsIdleArm(statusRes?.data?.state)) {
          await updateRobotArmPoseApiAction({
            angles: [0, -90, 90, -181.1578, 171.5495],
          });
        }
      };
      asyncUpdatePose();
    }
  }, [
    isRobotArm,
    listCapturePosition,
    getRobotArmStatusApiAction,
    updateRobotArmPoseApiAction,
  ]);

  return (
    <>
      <div className="flex flex-col items-center shooting-position-page">
        <div className="page-title-margin">
          <Typography
            variant={TYPOGRAPHY_VARIANTS.H1}
            data-text={t(`${I18nNamespace.COMMON}:selectShootingPosition`)}
            className="page-title"
          >
            {t(`${I18nNamespace.COMMON}:selectShootingPosition`)}
          </Typography>
        </div>
        <div className="flex items-center justify-center webcam-frame">
          <div className="webcam-frame-side">
            <div className="shooting-times-wrapper">
              {map(range(CAPTURE_POSITION_TEMPLATE_MAX_LENGTH), (_r, ind) => (
                <div
                  className={cx(
                    'shooting-times-item',
                    ind === shootingTimesIndex ? 'is-active' : '',
                  )}
                >
                  <button
                    type="button"
                    onClick={handleClickSelectShootingTimes(ind)}
                  >
                    <Typography
                      component={TYPOGRAPHY_COMPONENT.STRONG}
                      variant={TYPOGRAPHY_VARIANTS.BODY}
                      className="shooting-times-item-title"
                    >
                      Lượt {ind + 1}
                    </Typography>
                    <Typography
                      component={TYPOGRAPHY_COMPONENT.PARAGRAPH}
                      variant={TYPOGRAPHY_VARIANTS.SMALL}
                      className={cx('shooting-times-item-position', {
                        haveNotChosen:
                          !listTimesMappingCapturePosition?.[ind]
                            ?.capturePositionName,
                      })}
                    >
                      {listTimesMappingCapturePosition?.[ind]
                        ?.capturePositionName ||
                        t(`${I18nNamespace.COMMON}:haveNotChosen`)}
                    </Typography>
                  </button>
                </div>
              ))}
            </div>
          </div>
          <div
            className="dashed-border-select-frame webcam-frame-wrapper"
            style={{
              aspectRatio: `${currentLayout?.ratioX || 1} / ${
                currentLayout?.ratioY || 1
              }`,
            }}
          >
            <img
              src={liveViewUrl(currentLayout?.ratio || 1, liveViewHash)}
              alt="camera"
            />
          </div>
          <div className="webcam-frame-side">
            <Carousel
              className="slide-carousel"
              slides={map(
                range(
                  1,
                  Math.ceil(
                    (listCapturePosition?.totalRecord || 0) /
                      NUMBER_OF_SHOOTING_POSITION_PER_PAGE,
                  ) + 1,
                ),
                (_, ind) => (
                  <div className="shooting-times-wrapper shooting-position-wrapper">
                    {map(
                      slice(
                        listCapturePosition?.data,
                        ind * NUMBER_OF_SHOOTING_POSITION_PER_PAGE,
                        (ind + 1) * NUMBER_OF_SHOOTING_POSITION_PER_PAGE,
                      ),
                      (item) => (
                        <div className="shooting-times-item shooting-position-item">
                          <button
                            type="button"
                            onClick={() => {
                              handleClickSelectShootingPosition(item);
                            }}
                          >
                            <Typography
                              component={TYPOGRAPHY_COMPONENT.STRONG}
                              variant={TYPOGRAPHY_VARIANTS.H2}
                              className="shooting-times-item-title shooting-position-item-title"
                            >
                              {item?.name}
                            </Typography>
                          </button>
                        </div>
                      ),
                    )}
                  </div>
                ),
              )}
            />
          </div>
        </div>
      </div>
      <PageActions
        NextButtonProps={{
          children: t(`${I18nNamespace.COMMON}:continue`),
          disabled:
            !size(listTimesMappingCapturePosition) ||
            size(listTimesMappingCapturePosition) !==
              size(compact(listTimesMappingCapturePosition)),
        }}
        handleContinue={handleNext}
        hidePrev
      />
    </>
  );
}

export default ShootingPosition;
